import LogService from '@/services/log.service';
import AgentService from '@/services/agent.service';
import dh from '@/helpers/date.helper';

const service = new LogService();

export default {
    name: 'logs',
    components: {},
    props: [],
    data() {
        return {
            logs: [],
            perPage: 10,
            totalRows: 0,
            currentPage: 1,
            searchVal: '',
            selectedTypeFilter: 'All',
            selectedUsersFilter: 'All',
            selectedDateFilter: '',
            selectedTimeFilter: '',
            users: [],
            types: [
                {text: 'Get', value: 'Get'},                
                {text: 'Create', value: 'Create'},                
                {text: 'Remove', value: 'Remove'},                
                {text: 'Update', value: 'Update'},                
                {text: 'Success', value: 'Success'},                
                {text: 'Error', value: 'Error'},                
                {text: 'Import', value: 'Import'},                
                {text: 'Export', value: 'Export'},                
                {text: 'All', value: 'All'}
            ],
            selectedError: null,
            showModal: false
        }
    },
    computed: {},
    async mounted() {        
        this.list();        
        if(this.$getUser().permissions.isAdmin) {
            this.getOwnAgents();
        }

    },
    filters: {
        parseDate(value) {
            return dh.prettyFullDate(value);
        },
        parseCompany(value) {
            return value && value.Company ? value.Company.Name : "Root";
        },
        parseDescription(value) {
            if(value.includes(':'))
                return value.split(':')[0]
            return value    
        }        
    },
    watch: {
        selectedTypeFilter(newVal) {
            if(newVal !== "") {
                this.searchVal = this.searchVal === "" ? `type=${newVal}` : this.filter(`type=${newVal}`);                
                this.list();
            }
        },
        selectedDateFilter(newVal) {
            if(newVal !== "") {                
                this.searchVal = this.searchVal === "" ? `date=${newVal}` : this.filter(`date=${newVal}`);                                 
                this.setTime();
                this.list();
            }
        },
        selectedUsersFilter(newVal) {
            if(newVal !== "") {
                this.searchVal = this.searchVal === "" ? `user=${newVal}`: this.filter(`user=${newVal}`);            
                this.list();
            }
        },
        selectedTimeFilter(newVal) {
            if(newVal !== "") {
                this.searchVal = this.searchVal === "" ? `time=${newVal}`: this.filter(`time=${newVal}`);
                this.list();
            }
        },
        async perPage(newVal){
            if(newVal !== "") {                
                await this.list();
            }
        }
    },
    methods: {

        async list(page) {

            this.currentPage = page || 1;

            let skip = this.perPage * (this.currentPage - 1);

            const res = await service.list(this.perPage, skip, this.searchVal);

            if (res && !res.error) {
                this.logs = res.data.logs;
                this.totalRows = res.data.count;
            }
        },

        async getOwnAgents() {
            const service = new AgentService();

            const res = await service.ownAgents();
            if (res && !res.error) {
                this.users = res.data;
            }
        },

        async setPerPage(num) {
            this.perPage = +(num);
            this.list();
        },

        next() {
            this.list(this.currentPage + 1);
        },

        prev() {
            this.list(this.currentPage - 1);
        },

        filter(value) {            
            let newFilter = "";           
            if(this.searchVal.includes(value.split('=')[0])) {
                if(this.searchVal.includes('&')) {                
                    let aux = this.searchVal.split('&');
                    for (let i in aux) {                        
                        if(typeof aux[i] === 'string' && aux[i].includes(value.split('=')[0])) {
                            aux[i] = value;
                        }
                    }
                    
                    newFilter = aux.join('&');
                } else {
                    newFilter = value;    
                }    
            } else {
                newFilter = `${this.searchVal}&${value}`;
            }                     
            return newFilter;
        },

        resetFilter() {
            this.searchVal = "";
            this.selectedTypeFilter = 'All';
            this.selectedUsersFilter = 'All';                             
            this.selectedDateFilter = '';   
            this.selectedTimeFilter = '';   
            this.list();                          
        },

        parseType(value) {
            switch (value) {
                case 'Error':                    
                    return 'ez-icon-error'; 
                case 'Export':                    
                    return 'ez-icon-download';
                case 'Import':                    
                    return 'ez-icon-upload';                    
                case 'Remove':                    
                    return 'ez-icon-warning'; 
                case 'Create':                    
                    return 'ez-icon-add-circle';                       
                case 'Update':                    
                    return 'ez-icon-update';                    
                case 'Success':                    
                    return 'ez-icon-checkmark-circle';                    
                default:                    
                    return 'ez-icon-info';                    
            }
        },

        colorType(value) {
            switch (value) {
                case 'Error':                    
                    return 'error'; 
                case 'Export':                    
                    return 'export';
                case 'Import':                    
                    return 'import';                        
                case 'Remove':                    
                    return 'warning';                    
                case 'Create':                    
                    return 'create'; 
                case 'Update':                    
                    return 'info';                    
                case 'Success':                    
                    return 'success';                    
                default:                    
                    return 'get';                    
            }
        },
        setTime() {            
            this.selectedTimeFilter = "";
        },
        show(log) {
            this.showModal = true;
            this.$refs.showErrorModal = true;
            this.selectedError = log;
        }        

    }
}
