import IService from './iservice';
import config from "@/config";
import store from "@/store/store";

const server_base = config[config.stage].ws_server;

class MigrationService extends IService {
    constructor(http) {
        super(http);
        this.store = store;
    }

    async replaceLeadCarHistory() {
        try {
            this.addLoading('loading');
            const response = await this.http.put(`${server_base}/migration/replace_lead_car_history`);
            this.delLoading('loading');
            return response.data;
        } catch (e) {
            this.delLoading('loading');
            return null;
        }
    }

    async addCustomertoChatClient() {
        try {
            this.addLoading('loading');
            const response = await this.http.put(`${server_base}/migration/add_customer_to_chatclient`);
            this.delLoading('loading');
            return response.data;
        } catch (e) {
            this.delLoading('loading');
            return null;
        }
    }

    async addChatClientToLead() {
        try {
            this.addLoading('loading');
            const response = await this.http.put(`${server_base}/migration/add_chatclient_to_lead`);
            this.delLoading('loading');
            return response.data;
        } catch (e) {
            this.delLoading('loading');
            return null;
        }
    }

    async setAgendaJobs() {
        try {
            this.addLoading('loading');
            const response = await this.http.put(`${server_base}/migration/set_agenda_jobs`);
            this.delLoading('loading');
            return response.data;
        } catch (e) {
            this.delLoading('loading');
            return null;
        }
    }

    async addMyOpportunityToCustomer() {
        try {
            this.addLoading('loading');
            const response = await this.http.put(`${server_base}/migration/add_my_opportunity_to_customer`);
            this.delLoading('loading');
            return response.data;
        } catch (e) {
            this.delLoading('loading');
            return null;
        }
    }
}

export default MigrationService;