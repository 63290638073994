import {mapActions, mapGetters} from 'vuex';

import MigrationService from '@/services/migration.service';

const service = new MigrationService();

export default {
    name: 'Migrations',

    data() {
        return {
            leadCarHistory: {
                loading: false,
                results: -1,
            },
            customerToChatClient: {
                loading: false,
                results: -1,
            },
            chatClientToLead: {
                loading: false,
                results: -1,
            },
            agendaJobs: {
                loading: false,
                results: -1,
            },
            myOpportunity: {
                loading: false,
                results: -1,
            }
        }
    },

    sockets: {
        MIGRATION_SCRIPT_END(data) {
            this[data.type].loading = false;
            this[data.type].results = data.results;

            this.setUploading(false);

            this.$notify({
                type: 'success',
                message: `Script ${data.type} success. ${this[data.type].results} fields updated.`
            });
        },
    },

    computed: {
        ...mapGetters(['getUploading']),
    },

    methods: {
        ...mapActions(['setUploading']),

        async replaceLeadCarHistory() {
            this.leadCarHistory.loading = true;
            this.leadCarHistory.results = -1;

            this.setUploading(true);

            await service.replaceLeadCarHistory();
        },

        async addCustomerToChatClient() {
            this.customerToChatClient.loading = true;
            this.customerToChatClient.results = -1;

            this.setUploading(true);

            await service.addCustomertoChatClient();
        },

        async addChatClientToLead() {
            this.chatClientToLead.loading = true;
            this.chatClientToLead.results = -1;

            this.setUploading(true);

            await service.addChatClientToLead();
        },

        async setAgendaJobs() {
            this.agendaJobs.loading = true;
            this.agendaJobs.results = -1;

            this.setUploading(true);

            await service.setAgendaJobs();
        },

        async addMyOpportunityToCustomer() {
            this.myOpportunity.loading = true;
            this.myOpportunity.results = -1;

            this.setUploading(true);

            await service.addMyOpportunityToCustomer();
        },
    }
}